import { FC } from 'react';
import { OverlayTrigger, OverlayTriggerProps, Tooltip } from 'react-bootstrap';

interface OwnTooltipProps {
  placement?: OverlayTriggerProps['placement'];
  children: OverlayTriggerProps['children'];
  title: string | JSX.Element;
  disabled?: boolean;
}

export const ToolTip: FC<OwnTooltipProps> = ({
  placement,
  children,
  title,
  disabled,
}) => {
  return (
    <OverlayTrigger
      placement={placement || 'top'}
      delay={{ show: 100, hide: 400 }}
      overlay={
        disabled ? (
          <span></span>
        ) : (
          <Tooltip id={`button-tooltip-${title}`}>{title}</Tooltip>
        )
      }
    >
      {children}
    </OverlayTrigger>
  );
};

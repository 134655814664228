import { AxiosResponse } from 'axios';
import agent from './agent';
import { Company } from '../types/Company';
import { CompanyPermissionId } from '../types/Permissions';

const companyApi = {
  searchCompanies: async (
    query: string,
    hidePaywallCompanies: boolean,
  ): Promise<AxiosResponse<Company[]>> =>
    agent.get('/admin/company/search', {
      params: { query, hidePaywallCompanies },
    }),
  adminEdit: async (
    body: Pick<Company, 'id' | 'paywallActive' | 'title'> & {
      permissions: CompanyPermissionId[];
    },
  ): Promise<AxiosResponse<Company[]>> => agent.put('/admin/company', body),
  delete: async (id: string): Promise<AxiosResponse<string>> =>
    agent.delete(`/admin/company/${id}`),
  deleteMockData: async (companyId: string): Promise<AxiosResponse<string>> =>
    agent.delete(`/mockdata/${companyId}`),

  generateMockData: async (companyId: string): Promise<AxiosResponse<string>> =>
    agent.post(`/mockdata/${companyId}`),
};

export default companyApi;
